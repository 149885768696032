import React from "react"
import styled, { css } from "styled-components"
import { Fade } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import "../NavSlide.scss"
import { rem } from "src/utils/styling"

import SlideImg from "src/images/slide.svg"
import CoverMobile from "src/images/cover_mobile.png"
import Slider2Mobile from "src/images/slider2_mobile.png"
import Slider3Mobile from "src/images/slider3_mobile.png"
import UmeedImg from "src/images/umeed.svg"
import SlideTwoImg from "src/images/slide-two.png"
import AccurkardiaCover from "src/images/accurkardia_cover.png"
import AccurkardiaBg from "src/images/accurkardia_bg.png"
import AccurkardiaMobileBg from "src/images/accurkardia_bg_mobile.png"
import EsportoneImg from "src/images/esportone.png"
import Umeed from "src/images/umeed.png"
import wyhf from "src/images/logos/WYHF-logo.svg"
import NewSliderImg from "src/images/new-slider-three.png"
import esportsLogo from "src/images/logos/esports_white.png"
import HenosceneLogo from "src/images/logos/Henoscene-Final-Logo-All-White.png"
import ishiLogo from "src/images/logos/ishi_white.png"
import accurkardiaLogo from "src/images/logos/accurkardia_white.png"
import canidLogo from "src/images/logos/canid_white.png"
import ISHIMobileBg from "src/images/ishi-mobile-bg.png"
import ISHILogoImage from "src/images/ishi-logo.png"
import ISHICoverImage from "src/images/ishi-cover-bg.png"
import ISHIImage from "src/images/ishi-image.png"

const Container = styled.div`
  position: relative;
  padding: ${rem(60)}rem 0 0;
  @media screen and (min-width: 1100px) {
    padding: ${rem(90)}rem 0 0;
  }

  .slide-container {
    margin: 0;

    .react-slideshow-container .default-nav {
      position: absolute;
    }
    .react-slideshow-container .default-nav:first-of-type {
      left: 20px;
    }
    .react-slideshow-container .default-nav:last-of-type {
      right: 20px;
    }
  }

  @media screen and (max-width: 1100px) {
    .slide-container {
      .react-slideshow-container .default-nav {
        position: absolute;
        height: 25px;
        width: 25px;
      }
      .react-slideshow-container .default-nav:first-of-type {
        left: 0px;
      }
      .react-slideshow-container .default-nav:last-of-type {
        right: 0px;
      }
    }
  }
`

const Slide = styled.div`
  width: 100%;
  text-align: center;
  height: ${rem(600)}rem;
  background-size: contain;
  backgroun-repeat: no-repeat;
  background-image: url(${SlideImg});
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${rem(120)}rem;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    align-item: center;
    padding: 0 ${rem(20)}rem;
    height: ${rem(500)}rem;
    background-image: url(${Slider2Mobile});
  }
`

const SlideISHI = styled.div`
  width: 100%;
  text-align: center;
  height: ${rem(600)}rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${rem(120)}rem;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(${ISHICoverImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(76, 49, 146, 0.6);
    z-index: 2;
  }

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    background-size: cover;
    justify-content: center;
    align-item: center;
    padding: 0 ${rem(20)}rem;
    height: ${rem(500)}rem;
    &::before {
      background-image: url(${ISHIMobileBg});
    }
  }
`

const SlideTwo = styled(Slide)`
  background-image: url(${SlideTwoImg});
  @media screen and (max-width: 1100px) {
    background-image: url(${Slider3Mobile});
  }
`
const SlideAccurkardia = styled(Slide)`
  background-image: url(${AccurkardiaBg});
  @media screen and (max-width: 1100px) {
    background-image: url(${AccurkardiaMobileBg});
  }
`
const SlideThree = styled(Slide)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: 0;
  background-image: url(${NewSliderImg});
  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 0;
    background-image: url(${CoverMobile});
  }
`
const TextContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  z-index: 3;
  @media screen and (max-width: 1100px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 ${rem(20)}rem;
  }
`

const TextImage = styled.img`
  display: none;
  @media screen and (max-width: 1100px) {
    display: block;
    max-width: 200px;
    ${props =>
      props.umeed &&
      css`
        width: 50%;
      `}
    ${props =>
      props.ishi &&
      css`
        width: 70px;
      `}
  }
  @media screen and (max-width: 500px) {
    display: block;
    width: 80%;
    ${props =>
      props.umeed &&
      css`
        width: 50%;
      `}
    ${props =>
      props.ishi &&
      css`
        width: 70px;
      `}
  }
`

const TextHeader = styled.h1`
  font-weight: 800;
  font-size: ${rem(45)}rem;
  line-height: 50px;
  color: #fff;
  text-align: left;
  @media screen and (max-width: 1100px) {
    font-size: ${rem(22)}rem;
    line-height: ${rem(39)}rem;
    text-align: center;
    width: 100%;
  }
`

const SubTextHeader = styled.p`
  font-size: ${rem(18)}rem;
  width: 80%;
  line-height: 30px;
  color: #eaeaea;
  text-align: left;
  font-weight: normal;
  margin: ${rem(20)}rem 0 0;
  @media screen and (max-width: 1100px) {
    font-size: ${rem(13)}rem;
    line-height: ${rem(24)}rem;
    text-align: center;
    margin: ${rem(20)}rem 0;
    width: 100%;
  }
`

const TextDescription = styled.p`
  font-size: ${rem(16)}rem;
  font-weight: 800;
  text-align: left;
  margin: ${rem(40)}rem 0 0;
  color: #fff;

  a {
    color: #fff;
    text-decoration: underline;
  }

  @media screen and (max-width: 1100px) {
    text-align: center;
    margin: ${rem(30)}rem 0 0;
  }
`

const SlideImage = styled.img`
  width: 50%;
  height: auto;
  z-index: 3;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`

const CompanyImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  padding: 30px;
  width: 100%;
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
  }
`

const CompanyImage = styled.img`
  margin-right: 60px;
  ${props =>
    props.esports &&
    css`
      height: 25px;
    `}
  ${props =>
    props.wyhf &&
    css`
      height: 40px;
    `}
  ${props =>
    props.accurkardia &&
    css`
      height: 25px;
    `} ${props =>
  props.ishi &&
  css`
    height: 30px;
  `};
  @media screen and (max-width: 1100px) {
    margin: 0 20px 20px 0;
    ${props =>
      props.esports &&
      css`
        height: 18px;
      `}
    ${props =>
      props.accurkardia &&
      css`
        height: 18px;
      `} ${props =>
  props.ishi &&
  css`
    height: 23px;
  `}
    ${props =>
      props.wyhf &&
      css`
        height: 40px;
      `}
  }
`

const SlideThreeTextContainer = styled.div`
  padding: ${rem(120)}rem ${rem(150)}rem;
  width: 100%;
  @media screen and (max-width: 1100px) {
    padding: ${rem(90)}rem ${rem(20)}rem;
    width: 100%;
  }
`

const NewSlide = () => {
  return (
    <Container>
      <div className="slide-container">
        <Fade indicators={true} arrows={true} autoplay={true} duration={15000}>
          <div className="each-fade">
            <SlideThree>
              <SlideThreeTextContainer>
                <TextContainer>
                  <TextHeader>
                    Building and scaling the world’s most ambitious startups
                  </TextHeader>
                </TextContainer>
              </SlideThreeTextContainer>
              <CompanyImageContainer>
                <CompanyImage esports src={HenosceneLogo} />
                <CompanyImage ishi src={ishiLogo} />
                <CompanyImage accurkardia src={accurkardiaLogo} />
                <CompanyImage esports src={canidLogo} />
                <CompanyImage wyhf src={wyhf} />
              </CompanyImageContainer>
            </SlideThree>
          </div>
          <div className="each-fade">
            <SlideAccurkardia>
              <TextContainer>
                <TextHeader>Upgrading Cardiac Care</TextHeader>
                <SubTextHeader>
                  Nearly 5% of the US population suffers from a cardiac
                  arrhythmia. Current solutions are entirely inadequate to
                  effectively monitor and triage this population. Archimydes is
                  helping AccurKardia build solutions that change this
                  narrative.
                </SubTextHeader>
                <TextImage umeed src={accurkardiaLogo} />
                <TextDescription>
                  AccurKardia announces{" "}
                  <a
                    href="https://www.businesswire.com/news/home/20230711025271/en/AccurKardia%E2%80%99s-AccurECG%E2%84%A2-Analysis-System-Receives-FDA-510-k-Clearance#:~:text=Clearance%20%7C%20Business%20Wire-,AccurKardia's%20AccurECG%E2%84%A2%20Analysis%20System%20Receives%20FDA%20510(k)%20Clearance,zip%20code%20of%20the%20USA.#"
                    target="_blank"
                  >
                    FDA approval
                  </a>
                </TextDescription>
              </TextContainer>
              <SlideImage src={AccurkardiaCover} alt="Accurkardia" />
            </SlideAccurkardia>
          </div>
          <div className="each-fade">
            <SlideISHI>
              <TextContainer>
                <TextHeader>
                  Turning Heart Failure into Heart Success
                </TextHeader>
                <SubTextHeader>
                  Estimates suggest that over 8 million people are experiencing
                  heart failure in the United States alone. ISHI provides an
                  ecosystem of cardiac devices and implanted sensors to provide
                  continuous monitoring and simplified heart health education
                  for patients to combat this increasing epidemic.
                </SubTextHeader>
                <TextImage ishi src={ISHILogoImage} />
                <TextDescription>
                  Successfully secured{" "}
                  <a
                    href="https://www.prnewswire.com/news-releases/ishi-health-secures-4m-to-transform-heart-failure-care-across-the-disease-risk-spectrum-302069168.html"
                    target="_blank"
                  >
                    Seed funding
                  </a>{" "}
                  in February 2024
                </TextDescription>
              </TextContainer>
              <SlideImage src={ISHIImage} alt="ISHI" />
            </SlideISHI>
          </div>
          <div className="each-fade">
            <Slide>
              <TextContainer>
                <TextHeader>Helping India Fight Covid</TextHeader>
                <SubTextHeader>
                  April & May 2021 were some of the most harrowing times in
                  India’s history. In under 2 weeks we built an app connecting
                  patients and caregivers to volunteer clinicians from across
                  the country.
                </SubTextHeader>
                <TextImage umeed src={Umeed} />
                <TextDescription>
                  Supporting COVID patients through{" "}
                  <a
                    href="https://www.forbesindia.com/article/covid19-frontline-warriors/these-youngsters-are-bridging-the-urbanrural-healthcare-divide/68499/1"
                    target="_blank"
                  >
                    technology
                  </a>
                </TextDescription>
              </TextContainer>
              <SlideImage src={UmeedImg} alt="Umeed" />
            </Slide>
          </div>
        </Fade>
      </div>
    </Container>
  )
}

export const HealthTechSlider = () => {
  return (
    <Container>
      <div className="slide-container">
        <Fade indicators={true} arrows={true} autoplay={true} duration={15000}>
          <div className="each-fade">
            <SlideISHI>
              <TextContainer>
                <TextHeader>
                  Turning Heart Failure into Heart Success
                </TextHeader>
                <SubTextHeader>
                  Estimates suggest that over 8 million people are experiencing
                  heart failure in the United States alone. ISHI provides an
                  ecosystem of cardiac devices and implanted sensors to provide
                  continuous monitoring and simplified heart health education
                  for patients to combat this increasing epidemic.
                </SubTextHeader>
                <TextImage ishi src={ISHILogoImage} />
                <TextDescription>
                  Successfully secured{" "}
                  <a
                    href="https://www.prnewswire.com/news-releases/ishi-health-secures-4m-to-transform-heart-failure-care-across-the-disease-risk-spectrum-302069168.html"
                    target="_blank"
                  >
                    Seed funding
                  </a>{" "}
                  in February 2024
                </TextDescription>
              </TextContainer>
              <SlideImage src={ISHIImage} alt="ISHI" />
            </SlideISHI>
          </div>
          <div className="each-fade">
            <SlideAccurkardia>
              <TextContainer>
                <TextHeader>Upgrading Cardiac Care</TextHeader>
                <SubTextHeader>
                  Nearly 5% of the US population suffers from a cardiac
                  arrhythmia. Current solutions are entirely inadequate to
                  effectively monitor and triage this population. Archimydes is
                  helping AccurKardia build solutions that change this
                  narrative.
                </SubTextHeader>
                <TextImage umeed src={accurkardiaLogo} />
                <TextDescription>
                  AccurKardia announces{" "}
                  <a
                    href="https://www.businesswire.com/news/home/20230711025271/en/AccurKardia%E2%80%99s-AccurECG%E2%84%A2-Analysis-System-Receives-FDA-510-k-Clearance#:~:text=Clearance%20%7C%20Business%20Wire-,AccurKardia's%20AccurECG%E2%84%A2%20Analysis%20System%20Receives%20FDA%20510(k)%20Clearance,zip%20code%20of%20the%20USA.#"
                    target="_blank"
                  >
                    FDA approval
                  </a>
                </TextDescription>
              </TextContainer>
              <SlideImage src={AccurkardiaCover} alt="Accurkardia" />
            </SlideAccurkardia>
          </div>

          <div className="each-fade">
            <Slide>
              <TextContainer>
                <TextHeader>Helping India Fight Covid</TextHeader>
                <SubTextHeader>
                  April & May 2021 were some of the most harrowing times in
                  India’s history. In under 2 weeks we built an app connecting
                  patients and caregivers to volunteer clinicians from across
                  the country.
                </SubTextHeader>
                <TextImage umeed src={Umeed} />
                <TextDescription>
                  Supporting COVID patients through{" "}
                  <a
                    href="https://www.forbesindia.com/article/covid19-frontline-warriors/these-youngsters-are-bridging-the-urbanrural-healthcare-divide/68499/1"
                    target="_blank"
                  >
                    technology
                  </a>
                </TextDescription>
              </TextContainer>
              <SlideImage src={UmeedImg} alt="Umeed" />
            </Slide>
          </div>
        </Fade>
      </div>
    </Container>
  )
}

export default NewSlide
