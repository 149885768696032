import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { rem } from "src/utils/styling"
import SectionHeaderWithBadge from "src/components/SectionHeaderWithBadge"
import earn_end_graphic from "src/images/earn_end_graphic.png"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 ${rem(20)}rem;

  @media screen and (max-width: 769px) {
    margin: ${rem(20)}rem ${rem(20)}rem;
  }
`

const Image = styled.img`
  margin-bottom: 30px;
  @media screen and (max-width: 500px) {
    margin-bottom: 30px;
  }
`

const SectionImageCard = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "#e1e3f5"};
  padding: ${rem(20)}rem;
  max-width: ${rem(1000)}rem;
  border-radius: ${rem(12)}rem;
  margin: 0 0 ${rem(40)}rem;

  img {
    width: ${rem(400)}rem;
    height: auto;
  }

  @media screen and (max-width: 769px) {
    margin: 0 0 ${rem(20)}rem;
    img {
      width: ${rem(300)}rem;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
  }
`

const TextContainer = styled.div`
  margin: 0 ${rem(30)}rem 0 ${rem(40)}rem;
  h4 {
    font-size: ${rem(20)}rem;
    font-weight: 800;
    color: #000;
  }
  p {
    font-size: ${rem(16)}rem;
    font-weight: 500;
    color: #101010;
    margin: ${rem(10)}rem 0 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0 ${rem(20)}rem 0 ${rem(20)}rem;
    h4 {
      font-size: ${rem(18)}rem;
    }
    p {
      font-size: ${rem(12)}rem;
    }
  }
  @media screen and (max-width: 600px) {
    margin: ${rem(20)}rem 0 0;

    h4 {
      font-size: ${rem(18)}rem;
    }
    p {
      font-size: ${rem(12)}rem;
    }
  }
`

const SectionContainer = ({
  backgroundColor,
  tagColor,
  cardData,
  tagTitle,
  tagText,
  tagDescription,
}) => {
  return (
    <Container>
      <Image src={earn_end_graphic} alt="" />
      <SectionHeaderWithBadge
        backgroundColor={backgroundColor}
        color={tagColor}
        body={tagDescription}
        heading={tagText}
        badgeText={tagTitle}
        bodyWidth="70%"
        bodySize={"20px"}
        badgeMarginBottom="20px"
      />
      {cardData?.map((item, idx) => (
        <SectionImageCard key={idx} backgroundColor={backgroundColor}>
          <img src={item.image} />
          <TextContainer>
            <h4>{item.title}</h4>
            <p>{item.description}</p>
          </TextContainer>
        </SectionImageCard>
      ))}
    </Container>
  )
}

export default SectionContainer
