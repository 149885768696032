import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { NewLandingPageHeader } from "src/components/Header"
import { rem } from "src/utils/styling"
import { darkTheme } from "src/utils/themes"
import { HealthTechSlider } from "src/components/apprenticeship/new-slider"
import IntroContainer from "src/components/health-tech/IntroContainer"
import SectionContainer from "src/components/health-tech/SectionContainer"
import engage_peer from "src/images/engage_peer.png"
import engage_community from "src/images/engage_community.png"
import grow_chat from "src/images/grow_chat.png"
import earn_micro from "src/images/earn_micro.png"
import earn_advisory from "src/images/earn_advisory.png"
import { PageButton } from "src/pages/code"
import { PageSection } from "src/components/PageSection"
import { Link } from "gatsby"
import { HiChevronRight } from "react-icons/hi"
import { LightFooter } from "src/components/Footer"
import TeamSectionContainer from "src/components/health-tech/TeamSectionContainer"

const Container = styled.div`
  max-width: 1100px;
  margin: ${rem(80)}rem auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`
const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(100)}rem ${rem(140)}rem;
  background: linear-gradient(283.7deg, #03162c 28.07%, #4d5357 185.43%);
  border-radius: 20px;
  width: 90%;
  margin: 0 auto -${rem(180)}rem;
  z-index: 9999;
  @media screen and (max-width: 500px) {
    padding: 60px;
  }
  @media screen and (min-width: 1100px) {
    width: 894px;
  }
`

const CTAContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`

const LandingPageSection = styled(PageSection)`
  padding: ${rem(50)}rem 0;
  position: relative;
  background-color: ${props => (props.color ? props.color : "transparent")};
  @media screen and (min-width: 1100px) {
    padding: ${rem(10)}rem 0;
  }
  @media screen and (max-width: 1100px) {
    padding: ${rem(5)}rem 0;
  }
  @media screen and (max-width: 550px) {
    padding: ${rem(20)}rem 0;
  }
`

const engageData = [
  {
    title: "Peer Roundtables",
    description:
      "Engage in dynamic peer roundtables designed to foster collaboration and idea exchange among top engineering leaders in healthtech and life sciences. These sessions provide a platform to discuss industry challenges, share insights, and drive innovation collectively.",
    image: engage_peer,
  },
  {
    title: "A WhatsApp communitiity",
    description:
      "Join our exclusive WhatsApp community and connect with like-minded professionals to stay updated with industry trends and instantly access valuable resources, support, and opportunities.",
    image: engage_community,
  },
]

const growData = [
  {
    title: "Fireside Chats",
    description:
      "Participate in our fireside chats with industry pioneers and thought leaders. These sessions offer an unparalleled opportunity to gain deep insights, ask questions, and learn from the experiences of those who have successfully navigated the complexities of healthtech and life sciences.",
    image: grow_chat,
  },
]

const earnData = [
  {
    title: "Micro",
    description:
      "Sed hendrerit eros lorem, a finibus diam condimentum et. Nunc nec aliquet ex. Vestibulum gravida placerat dui",
    image: earn_micro,
  },
  {
    title: "Advisory",
    description:
      "Sed hendrerit eros lorem, a finibus diam condimentum et. Nunc nec aliquet ex. Vestibulum gravida placerat dui",
    image: earn_advisory,
  },
]

const HealthTech = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <NewLandingPageHeader />
      <HealthTechSlider />
      <Container>
        <IntroContainer />
        <SectionContainer
          backgroundColor={"#e1e3f5"}
          tagColor={"#764ba2"}
          cardData={engageData}
          tagTitle={"Engage"}
          tagText={"Connect and Collaborate"}
          tagDescription={
            "Engage with fellow professionals to foster collaboration and build robust networks within the industry."
          }
        />
        <SectionContainer
          backgroundColor={"#FBE5D2"}
          tagColor={"#FF6A00"}
          cardData={growData}
          tagTitle={"Grow"}
          tagText={"Amplify Your Knowledge"}
          tagDescription={
            "Gain valuable knowledge from health techs top leaders in our fireside chats"
          }
        />
        <TeamSectionContainer
          backgroundColor={"#CFE9FC"}
          tagColor={"#009EFD"}
          tagTitle={"Earn"}
          tagText={"Utilize Your Expertise"}
          tagDescription={
            "Use your expertise to earn extra income and impact healthtech and life science companies."
          }
        />
      </Container>
      <LandingPageSection>
        <CTAContainer>
          <CTAContainerInner>
            <SectionTitle color={"#fff"} style={{ paddingBottom: 20 }}>
              Join the community today
            </SectionTitle>
            <a href="mailto:support@archimydes.com?subject=Request to Join Healthtech Community&body=Please provide the following information to join the community: %0D%0A %0D%0A Name: %0D%0A Preferred Email: %0D%0A Company: %0D%0A Current Title:">
              <PageButton type="solid">
                Join community
                <HiChevronRight size={22} />
              </PageButton>
            </a>
          </CTAContainerInner>
        </CTAContainer>
      </LandingPageSection>
      <LightFooter paddingTop={280} />
    </ThemeProvider>
  )
}

export default HealthTech
