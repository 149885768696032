import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { rem } from "src/utils/styling"
import SectionHeaderWithBadge from "src/components/SectionHeaderWithBadge"
import earn_end_graphic from "src/images/earn_end_graphic.png"
import semih_card from "src/images/semih_card.png"
import prasanna_card from "src/images/prasanna_card.png"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 ${rem(20)}rem;

  @media screen and (max-width: 768px) {
    margin: ${rem(20)}rem ${rem(20)}rem;
  }
`

const Image = styled.img`
  margin-bottom: 30px;
  @media screen and (max-width: 500px) {
    margin-bottom: 30px;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${rem(40)}rem;
  max-width: ${rem(1000)}rem;

  @media screen and (max-width: 768px) {
    gap: ${rem(20)}rem;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const TextContainer = styled.div`
  padding: ${rem(20)}rem ${rem(20)}rem ${rem(40)}rem;
  background-color: #f0f5f8;
  border-radius: ${rem(12)}rem;
  min-height: ${rem(220)}rem;

  h4 {
    font-size: ${rem(20)}rem;
    font-weight: 800;
    color: #000;
  }
  p {
    font-size: ${rem(18)}rem;
    line-height: ${rem(28)}rem;
    font-weight: 500;
    color: #101010;
    margin: ${rem(10)}rem 0 0;
  }

  @media screen and (max-width: 768px) {
    margin: ${rem(20)}rem 0 0;
    min-height: ${rem(150)}rem;

    h4 {
      font-size: ${rem(18)}rem;
    }
    p {
      font-size: ${rem(12)}rem;
      line-height: ${rem(20)}rem;
    }
  }
`

const SectionImageCard = styled.div`
  flex: 1;
  img {
    width: 100%;
    height: 100%;
    margin-top: -20px;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
  }
`

const TeamSectionContainer = ({
  backgroundColor,
  tagColor,
  cardData,
  tagTitle,
  tagText,
  tagDescription,
}) => {
  return (
    <Container>
      <Image src={earn_end_graphic} alt="" />
      <SectionHeaderWithBadge
        backgroundColor={backgroundColor}
        color={tagColor}
        body={tagDescription}
        heading={tagText}
        badgeText={tagTitle}
        bodyWidth="70%"
        bodySize={"20px"}
        badgeMarginBottom="20px"
      />
      <RowContainer>
        <SectionImageCard>
          <TextContainer>
            <h4>Micro-Consulting</h4>
            <p>
              Join the Archimydes Guild and use your deep domain knowledge to
              generate additional income through micro-consulting with our
              innovative and diverse partners.
            </p>
          </TextContainer>
          <img src={prasanna_card} />
        </SectionImageCard>
        <SectionImageCard>
          <TextContainer>
            <h4>Advisory</h4>
            <p>
              Become an advisor with Archimydes to share your expertise and
              advise the most exciting health and life science companies on
              navigating the industry and achieving success.
            </p>
          </TextContainer>
          <img
            src={semih_card}
            onContextMenu={e => e.preventDefault()}
            draggable={false}
          />
        </SectionImageCard>
      </RowContainer>
    </Container>
  )
}

export default TeamSectionContainer
