import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { rem } from "src/utils/styling"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${rem(80)}rem 0;

  h1 {
    text-align: center;
    font-size: ${rem(46)}rem;
    line-height: ${rem(62)}rem;
    font-weight: 900;
    color: #000000;

    span {
      color: #35b6c3;
    }
  }

  p {
    max-width: ${rem(1000)}rem;
    margin: ${rem(20)}rem auto 0;
    font-size: ${rem(22)}rem;
    line-height: ${rem(30)}rem;
    font-weight: 500;
    text-align: center;
    color: #6f6f6f;
  }

  @media screen and (max-width: 1100px) {
    margin: ${rem(20)}rem ${rem(20)}rem;
    h1 {
      font-size: ${rem(22)}rem;
      line-height: ${rem(30)}rem;
    }
    p {
      font-size: ${rem(14)}rem;
      line-height: ${rem(22)}rem;
    }
  }
`

const IntroContainer = () => {
  return (
    <Container>
      <h1>
        Introducing the Archimydes <br />
        <span>Healthtech & Lifesceience Community</span>
      </h1>
      <p>
        The Archimydes HCLS community is geared towards bringing Engineering
        leaders together to innovate, collaborate, and transform the future of
        healthcare and life sciences.
      </p>
    </Container>
  )
}

export default IntroContainer
